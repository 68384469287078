.intro,
.intro-title {
	display: flex;
	justify-content: center;
	align-items: center;
}

.intro-title {
	flex-direction: column;
	padding: 4rem;
}

.intro-title p {
	align-self: self-end;
}

.intro {
	flex-direction: column;
	animation: load-in 500ms;
	padding: 5rem 0rem;
	color: var(--color3);
}

h1 {
	color: var(--color3);
	font-size: 2rem;
}

.colored {
	color: var(--color2);
	font-weight: 600;
}

.intro-text {
	margin-bottom: 4rem;
	transition: 700ms;
}

.intro-text > p {
	color: var(--color3);
	padding: 1rem 2rem;
}

.skillSet {
	-bottom: 10rem;
	padding: 0.5rem;
	color: var(--color2);
	transition: 800ms;
}

.skillSet > p,
.skills {
	text-align: center;
}

.skills {
	display: flex;
	gap: 1rem;
	align-items: start;
}

.skill {
	display: inline-block;
	position: relative;
}

.skill:hover .tooltip {
	visibility: visible;
}

.skill svg {
	font-size: 1.6rem;
}

.redux-logo {
	height: 24px;
}

.mysql-logo {
	height: 34px;
}

.tooltip {
	visibility: hidden;
	background-color: var(--color1);
	color: var(--color2);
	padding: 0.5rem;
	position: absolute;
	border-radius: 4px;
	z-index: 1;
	top: 2rem;
	left: 0;
}

.skill:nth-child(8) .tooltip {
	left: -1.2rem;
}

.intro-title,
.intro-text,
.skillSet,
.skill {
	opacity: 0;
	transform: translateX(-200px);
	transition: 600ms ease-in;
}

.skill:nth-child(1) {
	transition-duration: 900ms;
}

.skill:nth-child(2) {
	transition-duration: 1000ms;
}

.skill:nth-child(3) {
	transition-duration: 1100ms;
}

.skill:nth-child(4) {
	transition-duration: 1200ms;
}

.skill:nth-child(5) {
	transition-duration: 1300ms;
}

.skill:nth-child(6) {
	transition-duration: 1400ms;
}

.skill:nth-child(7) {
	transition-duration: 1500ms;
}

.skill:nth-child(8) {
	transition-duration: 1600ms;
}

.isVisible {
	transform: translateX(0);
	opacity: 1;
}

@media only screen and (min-width: 600px) {
	h1 {
		font-size: 2.5rem;
	}

	.intro-text > p {
		font-size: 1.2rem;
	}

	.skill > svg {
		font-size: 2rem;
	}

	.redux-logo {
		height: 29px;
	}

	.mysql-logo {
		height: 42px;
	}
}

@media only screen and (min-width: 820px) {
	.intro-text > p,
	.skillSet > p {
		font-size: 1.5rem;
	}

	h1 {
		font-size: 3rem;
	}

	.skill > svg {
		font-size: 2.5rem;
	}

	.redux-logo {
		height: 36px;
	}

	.mysql-logo {
		height: 50px;
	}
}

@media only screen and (min-width: 1050px) {
	.intro {
		max-width: 1400px;
		margin: 0 auto;
		padding: 7rem 0rem;
		animation: none;
	}

	.intro-text {
		align-self: flex-start;
		margin-left: 4rem;
	}

	.intro-text > p {
		max-width: 800px;
	}

	h1 {
		font-size: 4rem;
		border-radius: 4px 4px;
	}

	.tooltip {
		top: 2.8rem;
	}
}
