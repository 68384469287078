.contact-container {
	padding-top: 4rem;
}

.contact {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--color1);
	color: var(--light);
	margin: 2rem 0;
	padding: 1rem;
	opacity: 0;
	transition: 500ms ease-in;
}

.contact > h2 {
	padding: 1rem;
	font-size: 1.8rem;
	color: var(--color2);
}

form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

input,
textarea {
	width: 100%;
	padding: 0.3rem;
	margin-bottom: 1rem;
	font-size: 1rem;
	color: var(--color1);
	background-color: var(--color3);
	border: none;
	border-radius: 2px;
	transition: 300ms ease-in;
}

input:focus,
textarea:focus {
	outline: 2px solid var(--color2);
}

::placeholder {
	color: rgba(43, 45, 66, 0.5);
}

form > input[type="submit"] {
	width: 170px;
	background-color: var(--color3);
	color: var(--color1);
	font-size: 1.2rem;
	border: none;
	border-radius: 2px;
	padding: 0.7rem;
	margin-bottom: 2rem;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	cursor: pointer;
	transition: 250ms ease-in;
}

form > input[type="submit"]:focus {
	outline: none;
	background-color: var(--color2);
}

form > input[type="submit"]:hover {
	background-color: var(--color2);
	color: var(--color1);
}

.icon-container {
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	margin-top: 8rem;
}

.icon-container > a {
	font-size: 2rem;
	color: var(--color3);
	padding: 0.5rem;
	transition: 250ms ease-in;
	animation: load-in 500ms;
}

.icon-container > a:hover {
	color: var(--color2);
}

.isVisible {
	opacity: 1;
}

@media only screen and (min-width: 500px) {
	form {
		width: 460px;
	}
}

@media only screen and (min-width: 800px) {
	.contact {
		width: 700px;
		margin: 4rem auto;
		border-radius: 4px;
		box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
	}

	.contact > h2 {
		font-size: 2rem;
	}
}

@media only screen and (min-width: 1024px) {
	.contact {
		width: 800px;
	}

	.contact > h2 {
		font-size: 2.8rem;
	}

	input,
	textarea {
		font-size: 1.5rem;
	}

	form > input[type="submit"] {
		font-size: 1.5rem;
		width: 220px;
		padding: 1rem;
	}

	.icon-container > a {
		font-size: 2.5rem;
		padding: 1rem;
	}
}
