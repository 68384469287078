.main-navbar {
	position: fixed;
	top: 0;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 9;
}

.main-navbar > ul {
	display: flex;
	animation: load-in 1s;
}

.main-navbar > ul > li {
	padding: 0.8rem;
}

.logo-container {
	padding: 0.2rem;
	display: flex;
	align-items: center;
}

.logo {
	height: 40px;
}

.main-navbar ul > li > a {
	font-size: 1.2rem;
	color: var(--color3);
	background: linear-gradient(to right, var(--color2), var(--color2));
	background-repeat: no-repeat;
	background-size: 0 2px;
	background-position: 100% 100%;
	transition: all 300ms;
}

.main-navbar > ul > li > a:hover,
.main-navbar > ul > li > .active {
	background-size: 100% 2px;
	color: var(--color2);
}

@media only screen and (min-width: 1024px) {
	.main-navbar ul > li > a {
		font-size: 1.4rem;
	}

	.logo {
		height: 50px;
	}
}
