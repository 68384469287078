@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");

:root {
	--color1: rgba(43, 45, 66, 0.9);
	--color2: rgb(107, 212, 37);
	--color3: rgb(147, 163, 188);
	--color4: rgb(237, 242, 244);
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	font-family: "Roboto", sans-serif;
}

* > li {
	list-style-type: none;
}

* > a {
	text-decoration: none;
}

#root {
	background-image: url("/src/assets/new-keyboard.jpg");
	background-color: rgba(43, 45, 66, 0.7);
	background-size: cover;
	background-position: center;
	background-blend-mode: overlay;
	color: var(--light);
}

@keyframes load-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
