.projects-section {
	animation: load-in 500ms;
	color: var(--color3);
	padding-bottom: 4rem;
	animation: load-in 500ms;
}

.project-container-title {
	padding: 2rem;
	font-size: 2rem;
	color: var(--color2);
}

.project-container {
	margin-bottom: 1rem;
	background-color: var(--color1);
	width: 100%;
}

.project-container a {
	color: var(--color2);
}

.project-info {
	padding: 1rem;
}

.img-container {
	display: none;
}

.name {
	font-size: 1.5rem;
	margin-bottom: 1rem;
	font-weight: 500;
}

.description {
	font-size: 1.2rem;
	margin-bottom: 0.5rem;
}

.link-container {
	margin-top: 1rem;
	display: flex;
	justify-content: flex-start;
	justify-self: flex-end;
	gap: 1rem;
}

.link-container > a > button {
	padding: 0.4rem 0.7rem;
	font-size: 1rem;
	border: none;
	border-radius: 2px;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	background-color: var(--color3);
	color: var(--color1);
	cursor: pointer;
	transition: 250ms ease-in;
}

.link-container > a > button:hover {
	background-color: var(--color2);
}

.projects-container {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	justify-content: center;
	align-items: center;
	padding: 2rem;
}

.projects-container div {
	background-color: var(--color1);
	padding: 1rem;
	border-radius: 4px;
	box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
	cursor: pointer;
	opacity: 0;
	transform: translateX(-200px);
	transition: 600ms ease-in-out;
	pointer-events: none;
}

.projects-container div img {
	height: 150px;
}

.projects-container div:nth-child(1) {
	transition-duration: 900ms;
}

.projects-container .isVisible {
	transform: translateX(0);
	opacity: 1;
	pointer-events: all;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9;
}

@media only screen and (min-width: 500px) {
	.projects-section {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

@media only screen and (min-width: 800px) {
	.projects-section {
		margin: 0 auto;
	}

	.project-container-title {
		padding-bottom: 1rem;
	}

	.project-container {
		width: 600px;
		border-radius: 2px;
		box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
	}
}

@media only screen and (min-width: 1024px) {
	.projects-section {
		font-size: 1.5rem;
		padding: 4rem 1rem;
		animation: none;
		min-height: 600px;
	}

	.project-container-title {
		font-size: 3rem;
		padding-top: 1rem;
	}

	.project-container {
		width: 900px;
		padding: 1rem;
		margin: 2rem;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		border-radius: 4px;
	}

	.project-info {
		padding-right: 1.1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.name {
		font-size: 1.5rem;
	}

	.link-container > a > button {
		font-size: 1.1rem;
		padding: 0.6rem 1rem 0.6rem 1rem;
	}

	.img-container {
		display: block;
	}

	.img-container > img {
		padding-top: 1rem;
	}

	.projects-container {
		display: flex;
		flex-wrap: wrap;
		gap: 2rem;
		justify-content: center;
		align-items: center;
		padding: 2rem;
	}

	.projects-container div img {
		height: 200px;
	}
}
